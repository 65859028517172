import React from "react"
import Layout from "../components/Layout/Layout"
import { graphql } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Divider from "../components/Divider/Divider"
import ImageBlock from "../components/ImageBlock/ImageBlock"
import DetailBlock from "../components/DetailBlock/DetailBlock"
import SEO from "../components/seo"
import { Link } from "gatsby"


import styles from "./BlogPostTemplate.module.scss"

const shortcodes = { Divider, ImageBlock, DetailBlock }

const BlogPostTemplate = ({ data: { mdx }, pageContext }) => {
  return (
    <Layout>
      <SEO title={mdx.frontmatter.title} description={mdx.frontmatter.excerpt} />
      <div className="mt-16 mb-10">
        <h1>{mdx.frontmatter.title}</h1>
        {/* <p className="text-gray-500 font-semibold" >{mdx.frontmatter.excerpt}</p> */}

      </div>


      <div className={` ${styles.detail}`}>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
        {/* <div className="mb-12 mt-4">
             <Link to="/writing" >Back to All Posts</Link>
        </div> */}
      </div>


      <hr className="border-0 bg-gray-400 h-px w-full mt-8 mb-8" />


      <div className="flex w-full flex-wrap">      
      {pageContext.prev && (<div className="flex-grow mr-auto self-start pr-4"><Link to={pageContext.prev.frontmatter.slug} >Previous: {pageContext.prev.frontmatter.title}</Link></div>)}
      {pageContext.next && (<div className="self-end ml-auto"><Link to={pageContext.next.frontmatter.slug} >Next: {pageContext.next.frontmatter.title}</Link></div>)}
      </div>

      
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        excerpt
    }
  }
}
`

export default BlogPostTemplate
